import React from "react"
import {
    FeaturesSection, NavBar,
    ProductHeroSection, ProductSubheadingSection, ProductWhatMakesUsDifferentSection,
} from "@/layout"

const ProductPage: React.FC = () => {
    return (
        <div className={'container-product'}>
            <ProductHeroSection/>
            <ProductSubheadingSection/>
            <FeaturesSection/>
            <ProductWhatMakesUsDifferentSection/>
        </div>
    )
}

export default ProductPage
